import React from 'react'
import PropTypes from 'prop-types'
import Divider from '@components/Divider'

const styles = {
  inCard: {
    borderTopLeftRadius: `16px`,
    borderTopRightRadius: `16px`,
    maxWidth: `calc(100% + 4rem)`,
    marginLeft: `-2rem`,
    marginTop: `-2rem`
  },
}

const PostImage = ({ thumbnail, variant, inCard }) => {
  if (!thumbnail) return null

  return (
    <>
      <img
        src={thumbnail}
        alt="thumbnail"
        style={{ ...styles[variant], ...(inCard && styles.inCard) }}
      />
      <Divider space={3} />
    </>
  )
}

export default PostImage

PostImage.defaultProps = {
  variant: 'wide'
}

PostImage.propTypes = {
  variant: PropTypes.oneOf(['wide', 'full']),
  inCard: PropTypes.bool
}
