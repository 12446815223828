import React from "react"

const PostBody = ({ body }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: body,
      }}
    />
  )
}

export default PostBody
